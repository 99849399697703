import { math } from '@/utils/math.js';
import csvToJson from 'csvtojson';

/**
 * @description 传递给图表formatter的回调函数_用于将数值展示为两位小数
 * @param { value } number
 * @return {} number
 */
export function round2(value) {
	return value.toFixed(2);
}

/**
 * @description 通过formatter设置时间刻度间隔
 * @param { index } number
 * @return {} string
 */
export function time_label_formatter(value, index) {
	let remainder = index % 3;
	if (remainder === 0) {
		return '{HH}:{mm}';
	} else {
		return '';
	}
}

/**
 * @description 数组切割
 * @param { array } Array
 * @param { subGroupLength } Number
 * @return { Array } newArray
 */
export function group(array, subGroupLength) {
	let index = 0;
	let newArray = [];
	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}
	return newArray;
}

/**
 * @description 将头部N条数据拼接到数组末尾
 * @param { handleArr } Array
 * @param { sourceArr } Array
 * @param { sliceNum } Number
 * @return { Object } { handleArr, sourceArr }
 */
export function circularArray(handleArr, sourceArr, sliceNum) {
	handleArr = sourceArr.slice(0, sliceNum);
	sourceArr = sourceArr.slice(handleArr.length, sourceArr.length).concat(handleArr);
	return {
		handleArr,
		sourceArr,
	};
}

/**
 * @description 对象数组去重
 * @param {arr} Array
 * @param {key} string  判断的key
 * @return {Array}
 */
export function uniqueArr(arr, key) {
	let result = [];
	arr.forEach((item) => {
		if (!result.some((m) => m[key] === item[key])) {
			result.push(item);
		}
	});
	return result;
}

/**
 * @description 格式化时间
 * @param {val} String  - ISO | UTC 时间
 * @return {} String		-年-月-日 时:分:秒 || 0:0:0
 */
export function formatData(val) {
	if (val === '暂无') return '';
	if (val) {
		if (val == '0') {
			return '0:0:0';
		} else {
			const time = new Date(val);
			const y = time.getFullYear();
			let m = time.getMonth() + 1;
			let d = time.getDate();
			let h = time.getHours();
			let t = time.getMinutes();
			let s = time.getSeconds();
			m < 10 ? (m = '0' + m) : m;
			d < 10 ? (d = '0' + d) : d;
			h < 10 ? (h = '0' + h) : h;
			t < 10 ? (t = '0' + t) : t;
			s < 10 ? (s = '0' + s) : s;
			const newTime = y + '-' + m + '-' + d + ' ' + h + ':' + t + ':' + s;
			return newTime;
		}
		// return val == '0' ? '00:00:00' : new Date(val).toLocaleString().replaceAll('/', '-');
	} else {
		return '0:0:0';
	}
}

/**
 * @description 时分秒转时间戳
 * @param {time} timeString (00:00:00)
 * @returns {string} 时间戳（单位：秒）
 */
export function timeToSec(time) {
	if (time == '0') {
		time = '00:00:00';
	}
	var s = '';

	var hour = time.split(':')[0];
	var min = time.split(':')[1];
	var sec = time.split(':')[2];

	s = Number(hour * 3600) + Number(min * 60) + Number(sec);

	return s;
}

/**
 * @description 时间秒数格式化
 * @param {s} 时间戳（秒）
 * @returns {} 格式化后的时分秒
 */
export function secToTime(s) {
	var t;
	if (s > -1) {
		var hour = Math.floor(s / 3600);
		var min = Math.floor(s / 60) % 60;
		var sec = s % 60;
		if (hour < 10) {
			t = '0' + hour + ':';
		} else {
			t = hour + ':';
		}

		if (min < 10) {
			t += '0';
		}
		t += min + ':';
		if (sec < 10) {
			t += '0';
		}
		t += sec.toFixed(0);
	}
	return t;
}

/**
 * @description 年月日时分秒（2014.06.18 08:03:04）转时间戳
 * @param {s} 时间戳（单位：秒）
 * @returns {*} 格式化后的时间戳
 */
export function timestampToTime(timestamp) {
	// let date = new Date(timestamp*1000);
	let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	let Y = date.getFullYear() + '.';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
	let D = date.getDate() + ' ';
	// h = date.getHours() + ':';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	// m = date.getMinutes() + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	// s = date.getSeconds();
	let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
}

// 时分秒转 number (/h)
export const sectoNum = function (date) {
	if (date == '0' || date == '00' || !date) return 0;
	let h = Number(date.split(':')[0]);
	let m = math.divide(Number(date.split(':')[1]), 60);
	let s = math.divide(Number(date.split(':')[2]), 3600);
	return h + Number(m.toFixed(2)) + Number(s.toFixed(2));
};

/**
 * @description 时间段 分钟数是否连续
 * @param {timeArray} Array [2023-07-29 11:01:19, 2023-07-29 11:02:00, 2023-07-29 11:03:59]
 * @returns {} Boolean
 */
export const areMinutesContinuous = function (timeArray) {
	if (timeArray.length <= 1) {
		return true; // 单个时间或空数组认为是连续的
	}

	for (let i = 1; i < timeArray.length; i++) {
		const currentTime = new Date(timeArray[i]);
		const prevTime = new Date(timeArray[i - 1]);

		// 将时间转换为分钟数
		const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
		const prevMinutes = prevTime.getHours() * 60 + prevTime.getMinutes();

		// 如果相邻时间的分钟数差不是 1，则认为不连续
		if (currentMinutes - prevMinutes !== 1) {
			return false;
		}
	}

	return true; // 分钟数连续
};

/**
 * @description 截取字符串指定字符后面的字符
 * @param {S} String 字符串
 * @param {P} String 指定字符/字符串
 * @returns {String} S 截取后的字符串
 */
export const getSubstringAfterFirstSlash = function (S, P) {
	let slashIndex = S.indexOf(P);
	if (slashIndex !== -1) {
		return S.substring(slashIndex + 1);
	} else {
		return S;
	}
};

/**
 * @description 判断一个值是否为小数，然后将其保留三位小数。如果小数部分为0，则返回整数部分
 * @param {value} String
 * @returns {} String | Number
 */
export const formatDecimalThree = function (value) {
	// 判断是否为小数
	if (typeof value === 'number' && !Number.isInteger(value)) {
		// 保留两位小数
		value = value.toFixed(3);
		// 如果小数部分为0，则返回整数部分
		if (value.indexOf('.') === -1) {
			return parseInt(value);
		} else {
			return value;
		}
	} else if (typeof value === 'string' && value.indexOf('.') !== -1) {
		// 如果字符串中包含小数点，将其转为数值类型并进行处理
		value = parseFloat(value);
		// 保留两位小数
		value = value.toFixed(3);
		// 如果小数部分为0，则返回整数部分
		if (value.indexOf('.') === -1) {
			return parseInt(value);
		} else {
			return value;
		}
	} else {
		// 如果既不是数值也不是包含小数的字符串，返回原值
		return value;
	}
};

/**
 * @description 判断一个值是否为小数，然后将其保留两位小数。如果小数部分为0，则返回整数部分
 * @param {value} String
 * @returns {} String | Number
 */
export const formatDecimal = function (value) {
	// 判断是否为小数
	if (typeof value === 'number' && !Number.isInteger(value)) {
		// 保留两位小数
		value = value.toFixed(2);
		// 如果小数部分为0，则返回整数部分
		if (value.indexOf('.') === -1) {
			return parseInt(value);
		} else {
			return value;
		}
	} else if (typeof value === 'string' && value.indexOf('.') !== -1) {
		// 如果字符串中包含小数点，将其转为数值类型并进行处理
		value = parseFloat(value);
		// 保留两位小数
		value = value.toFixed(2);
		// 如果小数部分为0，则返回整数部分
		if (value.indexOf('.') === -1) {
			return parseInt(value);
		} else {
			return value;
		}
	} else {
		// 如果既不是数值也不是包含小数的字符串，返回原值
		return value;
	}
};

/**
 * @description 整理Influx返回的原始数据为对象数组
 * @param {rawData} String 原始数据
 * @param {fieldArr} Array 查询字段集合
 * @returns {result} Array 对象数组
 */
export const formatInfluxData = async function (rawData, fieldArr) {
	// 需要格式化时间的字段
	const formatFiled = ['collection_time'];
	//保存采集器id
	const collDeviceIDList = [];

	const paramsObj = fieldArr.reduce((obj, item) => {
		obj[item] = '';
		return obj;
	}, {});

	let flag = fieldArr.filter((item) => rawData.includes(item));
	let tableDataArray = '';
	if (rawData.includes('\r\n\r\n')) {
		tableDataArray = rawData.split('\r\n\r\n').filter((item) => item.length > 0);
		for (let i = 0; i < tableDataArray.length; i++) {
			if (tableDataArray[i].length > 0) {
				const splitStr = tableDataArray[i].split(',,,,,\r\n')[1];
				tableDataArray[i] = await csvToJson().fromString(splitStr);
			}
		}
	} else if (rawData.includes('\n\n')) {
		tableDataArray = rawData.split('\n\n').filter((item) => item.length > 0);
		for (let i = 0; i < tableDataArray.length; i++) {
			if (tableDataArray[i].length > 0) {
				const splitStr = tableDataArray[i].split(',,,,,\n')[1];
				tableDataArray[i] = await csvToJson().fromString(splitStr);
			}
		}
	}

	let result = tableDataArray.flat();
	let count = result.length / flag.length;
	for (let k = 0; k < result.length; k++) {
		// 保存所采集的设备 的id
		if (!collDeviceIDList.includes(result[k]['coll_device_id'])) {
			collDeviceIDList.push(result[k]['coll_device_id']);
		}

		for (let v = 0; v < fieldArr.length; v++) {
			if (result[k]._field && result[k]._field === fieldArr[v]) {
				if (k < count) {
					let cloneObj = JSON.parse(JSON.stringify(paramsObj));
					for (let g of fieldArr) {
						if (g === fieldArr[v]) {
							cloneObj[g] = formatFiled.includes(fieldArr[v])
								? formatData(result[k]._value)
								: formatDecimal(result[k]._value);
							cloneObj.time = formatData(result[k]._time);
						}
					}
					result[k % count] = cloneObj;
				} else {
					result[k % count] = Object.assign(result[k % count], {
						[fieldArr[v]]: formatFiled.includes(fieldArr[v])
							? formatData(result[k]._value)
							: formatDecimal(result[k]._value),
						time: formatData(result[k]._time),
					});
				}
			}
		}
	}
	result = result.slice(0, count);
	return { result, collDeviceIDList };
};

/**
 * @description 时长字符串转为数值 (保留6位)
 * @param {timeString}  String    -> "1天 0:0:0"
 * @returns {}	Number					  -> 1
 */
export const timeStringToNum = function (timeString) {
	timeString = timeString.replace(/\s/g, '');
	// 解析时间字符串
	const regex = /(\d+):(\d+):(\d+)|(\d+)天\s?(\d+):(\d+):(\d+)/;
	const match = timeString.match(regex);

	if (!match) {
		return '-'; // 无法解析的格式时
	}

	// 计算总秒数
	let totalSeconds = 0;
	if (match[1] !== undefined) {
		// 小时:分钟:秒钟 格式
		totalSeconds = parseInt(match[1]) * 3600 + parseInt(match[2]) * 60 + parseInt(match[3]);
	} else {
		// 天 小时:分钟:秒钟 格式
		totalSeconds =
			parseInt(match[4]) * 24 * 3600 + parseInt(match[5]) * 3600 + parseInt(match[6]) * 60 + parseInt(match[7]);
	}

	// 计算总天数并保留两位小数
	const totalDays = totalSeconds / (24 * 3600);
	return parseFloat(totalDays.toFixed(6));
};

/**
 * @description 数值转时间字符串 (以24小时|天 为基准)
 * @param {num} Number 整数 浮点数   -> 1
 * @returns {} 	String						  -> "1天 0:0:0"
 */
export const numToTimeString = function (num) {
	// 计算总秒数
	const totalSeconds = num * 24 * 3600;

	// 计算天数、小时、分钟和秒
	const totalDays = Math.floor(totalSeconds / (24 * 3600));
	const remainingSeconds = totalSeconds % (24 * 3600);
	const hours = Math.floor(remainingSeconds / 3600);
	const minutes = Math.floor((remainingSeconds % 3600) / 60);
	const seconds = Math.floor(remainingSeconds % 60);

	// 格式化为 X天HH:mm:ss 字符串
	const formattedTime = `${totalDays > 0 ? totalDays + '天 ' : ''}${String(hours).padStart(2, '0')}:${String(
		minutes
	).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

	return formattedTime;
};

/**
 * @description 百分比字符串转浮点数 整数 (保留四位 末尾不留0)
 * @param {percentageString} String "-59.03%" | "60.03%"
 * @returns {}	Number							 -0.5903 | 0.6003
 */
export const percentageStringToDecimal = function (percentageString) {
	// 移除百分号并转为浮点数
	const percentage = parseFloat(percentageString.replace('%', ''));

	// 转为小数，保留4位小数
	const decimal = (percentage / 100).toFixed(4);

	// 去掉小数末尾的零
	const trimmedDecimal = parseFloat(decimal);

	return isNaN(trimmedDecimal) ? null : trimmedDecimal;
};

/**
 * @description 浮点数 整数转百分比字符串
 * @param {decimal} Number  -0.5903 | 0.6003
 * @returns {} String			"-59.03%" | "60.03%"
 */
export const decimalToPercentageString = function (decimal) {
	if (decimal === '') return '-';
	// 将小数转为百分比，乘以100并保留4位小数
	const percentage = (decimal * 100).toFixed(4);

	// 去掉百分比末尾的零
	const trimmedPercentage = parseFloat(percentage);

	return isNaN(trimmedPercentage) ? '-' : `${trimmedPercentage}%`;
};

/**
 * @description 获取某年中某周的 周一与周日的日期
 * @param {year} Number
 * @param {weekNumber} Number
 * @returns {} Array  ->[起始日期'2024-01-07', 结束日期'2024-01-13']
 */
export const getMondayAndSunday = function (year, weekNumber) {
	const januaryFirst = new Date(year, 0, 1);

	const firstMonday = new Date(januaryFirst.getTime());
	firstMonday.setDate(januaryFirst.getDate() + ((8 - januaryFirst.getDay()) % 7));

	const desiredMonday = new Date(firstMonday.getTime());
	desiredMonday.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);

	const desiredSunday = new Date(desiredMonday.getTime());
	desiredSunday.setDate(desiredMonday.getDate() + 6);

	// Format "yyyy-mm-dd"
	const format = (date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	return [format(desiredMonday), format(desiredSunday)];
};

/**
 * @description 获取某一年 某个月 第一天与最后一天的日期
 * @param { year} Number 哪一年
 * @param { month} Number 第几个月
 * @returns {} Array ->[起始日期"yyyy-mm-dd", 结束日期"yyyy-mm-dd"]
 */
export const getFirstAndLastDay = function (year, month) {
	// 月份是从 0 开始的，因此减去 1
	const firstDay = new Date(year, month - 1, 1);
	const lastDay = new Date(year, month, 0);

	// 将日期格式化为 "yyyy-mm-dd"
	const format = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	return [format(firstDay), format(lastDay)];
};

/**
 * @description 根据某一周的周一日期 计算该日期是某年第几周
 * @param {dateString} String - "2024-01-01"
 * @returns {} String "2024年第1周"
 */
export const getWeekNumber = function (dateString) {
	const date = new Date(dateString);

	const januaryFirst = new Date(date.getFullYear(), 0, 1);
	const timeDifference = date - januaryFirst;
	const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)) + 1;

	return `${date.getFullYear()}年第${weekNumber}周`;
};

/**
 * @description 根据日期 判断是哪一个月
 * @param {dateString} Date
 * @returns {} Array
 */
export const getMonthFromDate = function (dateString) {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = date.getMonth() + 1; // 月份从0开始，需要加1

	return [year, month];
};

/**
 * @description 获取日期时间段内所有日期属于哪一年 第几周
 * @param {startDate} Date
 * @param {endDate} Date
 * @returns Array
 */
export const getWeeksInDateRange = function (startDate, endDate) {
	endDate = new Date(endDate);
	const weeks = [];
	let currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const currentYear = currentDate.getFullYear();
		const currentWeek = getISOWeek(currentDate);

		weeks.push({
			date: new Date(currentDate.getTime() + 8 * 60 * 60 * 1000).toISOString().split('T')[0],
			year: currentYear,
			cycle: currentWeek,
		});

		currentDate.setDate(currentDate.getDate() + 1);
	}

	return weeks;
};

function getISOWeek(date) {
	const d = new Date(date);
	d.setHours(0, 0, 0, 0);
	d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
	const yearStart = new Date(d.getFullYear(), 0, 1);
	return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}

/**
 * @description 获取日期时间段内所有日期属于哪一年 哪一个月
 * @param {startDate} Date
 * @param {endDate} Date
 * @returns Array
 */
export const getMonthsInDateRange = function (startDate, endDate) {
	const months = [];
	let currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth() + 1; // 注意月份从0开始，所以需要加1

		months.push({
			date: currentDate.toISOString().split('T')[0],
			year: currentYear,
			cycle: currentMonth,
		});

		// 将日期设置为下一天
		currentDate.setDate(currentDate.getDate() + 1);
	}
	return months;
};

/**
 * @description 根据时间段, 日期时间段, 返回每天对应的时间段 ISO格式
 * @param { dateRange} Array ["08:00:00", "12:00:00"]  1.一天内顺时时间段  2.元素:2
 * @param { timeSlots} Array ["2024-01-01 00:00:00", "2024-01-02 00:00:00"]  任意连续日期
 * @returns Array[Array]
 */
export const insertTimeSlots = function (dateRange, timeSlots) {
	const result = [];

	// 计算日期范围内的天数
	const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
	const startDate = new Date(dateRange[0]);
	const endDate = new Date(dateRange[1]);
	const days = Math.round((endDate - startDate) / oneDay) + 1;

	// 插入时间段
	for (let i = 0; i < days; i++) {
		const currentDate = new Date(startDate.getTime() + i * oneDay);

		// 添加时间段
		const timeSlotForDay = timeSlots.map((time) => {
			const [startHour, startMinute, startSecond] = time[0].split(':');
			const [endHour, endMinute, endSecond] = time[1].split(':');

			let startTime = new Date(currentDate);
			startTime.setHours(startHour);
			startTime.setMinutes(startMinute);
			startTime.setSeconds(startSecond);

			let endTime = new Date(currentDate);
			endTime.setHours(endHour);
			endTime.setMinutes(endMinute);
			endTime.setSeconds(endSecond);

			// 如果结束时间早于开始时间，表示跨天
			if (endTime < startTime) {
				// 减掉1000毫秒 次日零点改当日23:59:59 0点可能会导致数据重复
				endTime = new Date(currentDate.getTime() - 1000 + oneDay);
				// 或者：endTime.setDate(endTime.getDate() + 1);
			}

			// 将其转换为ISO格式
			startTime = new Date(new Date(startTime).getTime()).toISOString();
			endTime = new Date(new Date(endTime).getTime()).toISOString();

			return [startTime, endTime];
		});

		// 将每天的时间段数组推入结果数组
		result.push(timeSlotForDay);
	}

	return result;
};

/**
 * @description 获取当日 和 N天前的年月日
 * @param {dayNum} Number
 * @returns Array
 */
export const getTodaySevenDaysAgoDate = function (dayNum) {
	// 获取当前日期
	let today = new Date();

	// 获取当前日期的年月日
	let currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

	// 获取N天前的日期
	let paramDaysAgo = new Date(today.getTime() - dayNum * 24 * 60 * 60 * 1000);

	// 获取N天前日期的年月日
	let paramDaysAgoDate =
		paramDaysAgo.getFullYear() + '-' + (paramDaysAgo.getMonth() + 1) + '-' + paramDaysAgo.getDate();

	return [currentDate, paramDaysAgoDate];
};

/**
 * @description 根据日期返回同比环比日期
 * @param {dateRange} DataArray
 * @returns
 */
export const getComparisonDates = function (dateRange) {
	const startDate = new Date(dateRange[0]);
	const endDate = new Date(dateRange[1]);

	// 去年相同时间
	const lastYearStartDate = new Date(startDate);
	lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);
	const lastYearEndDate = new Date(endDate);
	lastYearEndDate.setFullYear(lastYearEndDate.getFullYear() - 1);

	// 上一周期的日期
	const prevPeriodStartDate = new Date(startDate);
	prevPeriodStartDate.setDate(prevPeriodStartDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));
	const prevPeriodEndDate = new Date(endDate);
	prevPeriodEndDate.setDate(prevPeriodEndDate.getDate() - (endDate.getDate() - startDate.getDate() + 1));

	return [
		[startDate, endDate],
		[lastYearStartDate, lastYearEndDate],
		[prevPeriodStartDate, prevPeriodEndDate],
	];
};

/**
 * @description 根据日期 时段数组, 判断日期属于哪一个时段. 返回对应数组元素
 * @param {date} Date
 * @param {periodList} Array
 */
export const belongTimePeriod = function (date, periodList) {
	// 将时间字符串转换为 Date 对象
	const dateObject = new Date(date);

	// 提取小时、分钟和秒
	const hours = dateObject.getHours();

	// 遍历数组，找到匹配的时间段
	let matchedRange = null;

	for (const range of periodList) {
		if (hours >= range.range_start && hours < range.range_end) {
			matchedRange = range;
			break;
		}
	}

	return matchedRange;
};

/**
 * @description 计算节能量
 * @description 节能生效时间之后的 总耗电量 / (1-节能率/100) * (节能率/100)
 */
export const computedEnergySaving = function (rateObj, dateStr, value) {
	let EnergySavingNum = 0;
	if (!rateObj.energy_saving_rate || !value) return EnergySavingNum;

	// 节能生效时间
	const rateDate = new Date(rateObj.energy_saving_effective_time).getTime();
	// 该数据的日期
	const currentDate = new Date(dateStr).getTime();

	// 当前时间大于节能生效时间
	if (currentDate > rateDate) {
		// 节能率
		const rate = rateObj.energy_saving_rate;
		const diff = math.subtract(1, rate);
		const result = formatDecimal(math.divide(value, diff));
		EnergySavingNum = formatDecimal(math.multiply(result, rate));
	}

	return EnergySavingNum;
};

/**
 * @description 补齐日期区间
 * @param {Array}	dateArr
 * @example
 * generateDateRange(['2024-01-10', '2024-01-05'])
 * 日期前大后小
 */
export const generateDateRange = function (dateArr) {
	const startDate = new Date(dateArr[1]);
	const endDate = new Date(dateArr[0]);

	// 如果两个日期是相邻的，直接返回
	if (startDate - endDate === 86400000) {
		// 86400000 毫秒数表示一天
		return [dateArr[1], dateArr[0]];
	}

	const dateRange = [];
	const currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const formattedDate = currentDate.toLocaleDateString('en-CA', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});

		dateRange.push(formattedDate);
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dateRange;
};

/**
 * @description 秒数转24小时为单位的字符串数字 (toFixed)
 * @param {Number} params
 * @returns String
 */
export const secondToHourNumber = function (params) {
	// 将字符串转换为数字
	const seconds = parseInt(params, 10);
	// 计算以24小时为单位的数值
	const hours = seconds / (24 * 60 * 60);
	// 保留两位小数
	return hours.toFixed(2);
};
