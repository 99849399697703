/**
 * 引入
 * import { math } from '@/utils/math.js';
 */

const $math = require('mathjs');

function comp(_func, args) {
	let t = $math.chain($math.bignumber(args[0]));
	for (let i = 1; i < args.length; i++) {
		t = t[_func]($math.bignumber(args[i]));
	}
	// 防止超过6位使用科学计数法
	return parseFloat(t.done());
}

// 处理精度丢失问题
export const math = {
	// 加法运算
	add() {
		return comp('add', arguments);
	},
	// 减法运算
	subtract() {
		return comp('subtract', arguments);
	},
	// 乘法运算
	multiply() {
		return comp('multiply', arguments);
	},
	// 除法运算
	divide() {
		return comp('divide', arguments);
	},
};
