/**
 *  [调用请求名称]: (params) => methods('/接口地址/', params, baseURL),
 */

import { get, post, postNoQs } from './http';

const apiList = {
	// 后端服务初始化 请求, 在登陆页面默认先过一下此请求
	initRequest: () => get('/login/'),

	// 登录
	login: (params) => post('/authorizations/', params),

	// influxDB查询
	influxQuery: (params) => postNoQs('/select_data/?url=query', params),

	// 查倍率
	magnification: () => get('/machine/transformer_magnification/'),

	// ---------------------------------------看板--------------------------------------

	// 请求频率
	boardConfig: (params) => post('/machine/board/config/', params),

	// 空压站运行信息
	stationInfo: (stationid) => get(`/board/station/info/?station_id=${stationid}`),

	// 故障信息
	faultInfo: (stationid) => get(`/board/fault/info/?station_id=${stationid}`),

	// 空压机运行信息
	compressorInfo: (stationid) => get(`/board/compressor/info/?station_id=${stationid}`),

	// 压力反馈 > 稳压值 告警值
	pressureInfo: (stationid) => get(`/board/pressure_fbk/info/?station_id=${stationid}`),

	// ------------------------------------筛选项数据-----------------------------------

	// 下拉框查询 空压站信息
	station: () => get('/machine/station/info/'),

	// 下拉框查询 空压机信息
	compressor: (stationid) => get(`/machine/compressor/info/?station_id=${stationid}`),

	// 下拉框查询 开关机类型
	onoffType: () => get('/machine/machine/open_type/'),

	// 下拉框查询 开关机人
	onoffPerson: () => get('/machine/user/open_or_close/'),

	// 下拉框查询 采集器连接类型
	collectorType: () => get('/machine/collector/type/'),

	// 下拉框查询 采集器连接地址  (未使用)
	// collectorAdd: () => get('/machine/collector/address/'),

	// 下拉框查询 空气干燥机设备名称
	dryingInfo: () => get('/machine/drying/info/'),

	// 空压站管理 带分页功能
	stationManager: (params) => get('/machine/station/manager/', params),

	// 空压站开关机
	kyzControl: (params) => post('/machine/station/control/', params),

	// 空压机管理 带分页功能
	compressorManager: (params) => get('/machine/compressor/manager/', params),

	// 空压机开关机
	compressorControl: (params) => post('/machine/compressor/control/', params),

	// 空压机工频变频修改
	compressorEditIcf: (params) => post('/machine/compressor/editicf/', params),

	// 空压机修改允许禁止
	compressorEditFs: (params) => post('/machine/compressor/editfs/', params),

	// -------------------------------------采集数据管理---------------------------------

	// 空压站开关机记录
	stationOnOffLog: (params) => get('/machine/station/log/', params),

	// 空压机开关机记录
	compressorOnOffLog: (params) => get('/machine/compressor/log/', params),

	// 故障记录
	faultLog: (params) => get('/machine/fault/log/', params),

	// 采集器连接记录
	connectLog: (params) => get('/machine/connection/log/', params),

	// 压力反馈获取Influxdb取值字段
	feedbackType: (stationid) => get(`/machine/pressure/feedback/type/?station_id=${stationid}`),

	// ------------------------------------数据统计分析-----------------------------------

	// 空压站运行时长
	stationRunTime: (params) => get('/collection/station/run_time/', params),

	// 空压站运行时长同比环比
	stationRunTimeTBHB: (params) => get('/collection/station/run_time/tbhb/', params),

	// 空压机运行时长
	compressorRunTime: (params) => get('/collection/compressor/run_time/', params),

	// 空压机运行时长同比环比
	compressorRunTimeTBHB: (params) => get('/collection/compressor/run_time/tbhb/', params),

	// 故障统计
	faultInfoCount: (params) => get('/collection/fault/info/', params),

	// 能耗费用 用电时段
	energyConsumption: (params) => get('/machine/energy/consumption/range/', params),

	// 节能量计算参数
	energyConservation: (params) => get('/machine/energy/conservation/info/', params),
};

export default apiList;
