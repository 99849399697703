<template>
	<div class="admin-button" @click="toAdmin">
		<i class="el-icon-s-grid admin-logo"></i>
		<span class="admin">后台管理</span>
	</div>
</template>

<script>
	export default {
		name: 'adminButton',

		data() {
			return {
				title: '后台管理',
			};
		},

		methods: {
			toAdmin() {
				let pathName = window.location.pathname;
				if (pathName) {
					const URL = window.location.origin + pathName;
					window.open(`${URL}api/admin/`, '_blank');
				} else {
					window.open(window.location.origin + 'api/admin/', '_blank');
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.admin-button {
		width: 100%;
		height: 56px;
		line-height: 56px;
		padding-left: 20px;
		font-size: 14px;
		color: #dcdcdc;
		cursor: pointer;

		&:hover {
			background-color: #607180;
		}

		.admin-logo {
			font-size: 18px;
			margin-right: 6px;
			vertical-align: middle;
		}
	}
</style>
