<template>
	<div :class="{ 'has-logo': showLogo }">
		<logo v-if="showLogo" :collapse="isCollapse" />
		<admin-button v-per="[2]" />
		<el-scrollbar wrap-class="scrollbar-wrapper">
			<el-menu
				:default-active="activeMenu"
				:collapse="isCollapse"
				:unique-opened="true"
				:collapse-transition="false"
				mode="vertical">
				<sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import Logo from './Logo';
	import SidebarItem from './SidebarItem';
	import adminButton from './adminButton';

	export default {
		components: {
			SidebarItem,
			Logo,
			adminButton,
		},

		computed: {
			...mapState({
				sidebar: (state) => state.sidebar,
			}),

			routes() {
				return this.$router.options.routes;
			},

			activeMenu() {
				const route = this.$route;
				const { meta, path } = route;
				if (meta.activeMenu) {
					return meta.activeMenu;
				}
				return path;
			},

			showLogo() {
				return this.sidebar.sidebarLogo;
			},

			isCollapse() {
				return !this.sidebar.opened;
			},
		},
	};
</script>
