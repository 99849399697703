export default {
	namespaced: true,

	state: {
		overviewBoardList: [
			{
				title: '设备状态',
				id: '1',
			},
		],
	},

	mutations: {},

	actions: {},

	getters: {},
};
