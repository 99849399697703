import Vue from 'vue';
import Vuex from 'vuex';
import sidebar from './module/sidebar';
import tagsView from './module/tags';
import user from './module/user';
import filters from './module/filters';
import board from './module/board';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isLoading: false,
		refresh: false,
	},

	getters: {},

	mutations: {
		reset(state) {
			state.user.userName = '';
			state.sidebar.opened = true;
			state.refresh = true;
		},

		handleShowLoading: (state, res) => {
			if (typeof res === 'boolean') {
				state.isLoading = res;
			} else {
				state.isLoading = false;
			}
		},
	},

	actions: {},

	modules: {
		sidebar,
		tagsView,
		user,
		filters,
		board,
	},
});

// 组件内使用
// import { mapState } from 'vuex';

// computed: {
//   ...mapState({
//     leftCompanyName: (state) => state.header.leftCompanyName,
//   }),
// },
