import Vue from 'vue';
import 'normalize.css/normalize.css';

import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import * as echarts from 'echarts';

import apiList from '@/http/config';
import { Local, Session, hasPathName, ClearWithPathNameLocal } from '@/utils/storage';
import { payload } from '@/utils/influxDBConfig.js';
import { formatInfluxData } from '@/utils/methods.js';

import '@/styles/index.scss'; // global css

import App from './App.vue';
import router from './router';
import store from './store';
import mixin from './mixin';
import moment from 'moment';
import '@/utils/permission';

Vue.config.productionTip = false;
Vue.prototype.$http = apiList;
Vue.prototype.$Session = Session;
Vue.prototype.$Local = Local;
Vue.prototype.$hasPathName = hasPathName;
Vue.prototype.$ClearWithPathNameLocal = ClearWithPathNameLocal;
Vue.prototype.$payload = payload;
Vue.prototype.$formatInfluxData = formatInfluxData;
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;

Vue.use(ElementUI);
Vue.mixin(mixin);

router.beforeEach((to, from, next) => {
	// 是否有登录信息
	let info = Local.get(hasPathName('info'));

	if (to.path !== '/login') {
		if (!info || !info.username || !info.token) {
			if (from.fullPath !== '/') Message({ message: '登录过期, 请重新登录', center: true });
			ClearWithPathNameLocal();
			setTimeout(() => {
				next('/login');
			}, 1000);
		} else {
			store.commit('user/saveUserInfo', info);
			try {
				let isUnload = Local.get(hasPathName('unload'));
				if (isUnload) {
					store.dispatch('filters/getfiltersData');
					Local.set(hasPathName('unload'), false);
				}
			} catch (error) {}
			next();
		}
	} else {
		next();
	}
});

// 页面刷新
window.addEventListener('beforeunload', function (event) {
	Local.set(hasPathName('unload'), true);
});

new Vue({
	router,
	store,
	render: (h) => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this;
	},
}).$mount('#app');
