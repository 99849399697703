export function payload(query, startTime = '', endTime = '') {
	return {
		"query": query,
		"extern": {
			"type": "File",
			"package": null,
			"imports": null,
			"body": [{
				"type": "OptionStatement",
				"assignment": {
					"type": "VariableAssignment",
					"id": {
						"type": "Identifier",
						"name": "v"
					},
					"init": {
						"type": "ObjectExpression",
						"properties": [{
								"type": "Property",
								"key": {
									"type": "Identifier",
									"name": "timeRangeStart"
								},
								"value": {
									"type": "DateTimeLiteral",
									"value": startTime
								}
							},
							{
								"type": "Property",
								"key": {
									"type": "Identifier",
									"name": "timeRangeStop"
								},
								"value": {
									"type": "DateTimeLiteral",
									"value": endTime
								}
							},
							{
								"type": "Property",
								"key": {
									"type": "Identifier",
									"name": "windowPeriod"
								},
								"value": {
									"type": "DurationLiteral",
									"values": [{
										"magnitude": 3600000,
										"unit": "ms"
									}]
								}
							}
						]
					}
				}
			}]
		},
		"dialect": {
			"annotations": [
				"group",
				"datatype",
				"default"
			]
		}
	};
}
