export default {
	namespaced: true,

	state: {
		userName: '',
		userType: '',
	},

	mutations: {
		saveUserInfo: (state, res) => {
			state.userName = res.username;
			state.userType = res.user_type;
		},
	},

	actions: {},

	getters: {},
};
