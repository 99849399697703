import router from '@/router';
import store from '@/store';
import axios from 'axios';
import QS from 'qs';
import { Message } from 'element-ui';
import { Local, hasPathName, ClearWithPathNameLocal } from '@/utils/storage';

axios.defaults.timeout = 8000;

// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		store.commit('handleShowLoading', true);

		if (!config.baseURL) {
			if (process.env.NODE_ENV === 'development') {
				// config.baseURL = 'http://192.168.0.89:8888';
				config.baseURL = 'http://192.168.0.11:8004';
			} else {
				let pathName = window.location.pathname;
				if (pathName) {
					pathName = pathName.slice(0, pathName.length - 1);
					config.baseURL = window.location.origin + pathName + '/api';
				} else {
					config.baseURL = window.location.origin + '/api';
				}
			}
		}

		// 设置token
		if (config.url !== '/authorizations/') {
			try {
				let TOKEN = Local.get(hasPathName('info')).token;

				config.headers['Authorization'] = 'Bearer ' + TOKEN; //Bearer 后面有空格
			} catch (error) {}
		}

		if (config.url.includes('url=query')) {
			// inFlux查询接口
			config.headers['Content-Type'] = 'application/json;charset=utf-8';
		} else if (config.method.toUpperCase() === 'POST') {
			config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}
		return config;
	},
	(error) => {
		return Promise.error(error);
	}
);

// 响应拦截器
axios.interceptors.response.use(
	(response) => {
		store.commit('handleShowLoading', false);

		if (response.status === 200) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	// 服务器状态码判断
	(error) => {
		store.commit('handleShowLoading', false);
		if (window.location.hash.includes('deviceStatus') || window.location.hash.includes('dataBoard')) {
			return Promise.reject(error.response);
		}

		if (error.response.status) {
			switch (error.response.status) {
				case 500:
					Message.error({ message: '请稍后再试-500 !', center: true });
					break;
				case 401:
					Message.error({ message: '登录过期, 请重新登录', center: true });
					store.commit('reset');
					ClearWithPathNameLocal();
					router.push('/login');
					break;
				case 404:
					Message.error({ message: '请稍后再试-404 !', center: true });
					break;
				default:
					Message.error({ message: '请稍后再试' + error.response.data.message, center: true }); // 其他错误，直接抛出错误提示
			}
			return Promise.reject(error.response);
		}
	}
);

/**
 * get方法，对应get请求
 * @param {String} baseURL [请求域名, 末尾不用"/"]
 * @param {String} url [请求的url地址, "前后带"/"]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, baseURL) {
	return new Promise((resolve, reject) => {
		axios({ method: 'get', baseURL, url, params })
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.data);
			});
	});
}

/**
 * post方法，对应post请求
 * @param {String} baseURL [请求的域名, 末尾不用"/"]
 * @param {String} url [请求的url地址, "前后带"/"]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, baseURL) {
	return new Promise((resolve, reject) => {
		axios({ method: 'post', baseURL, url, data: QS.stringify(params) })
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.data);
			});
	});
}

/**
 * post请求, 不进行qs格式化
 * @param {String} baseURL [请求的域名, 末尾不用"/"]
 * @param {String} url [请求的url地址, "前后带"/"]
 * @param {Object} data [请求时携带的参数]
 */
export function postNoQs(url, data, baseURL) {
	return new Promise((resolve, reject) => {
		axios({ method: 'post', baseURL, url, data })
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.data);
			});
	});
}

/**
 * get方法，对应get请求,直接在地址后面拼串的形式
 * @param {String} url [请求的url地址]
 * @param {String} params [请求时携带的参数]
 * @param {String} baseURL [请求域名]
 */
export function getDynamicynamic(url, params, baseURL) {
	return new Promise((resolve, reject) => {
		const completeUrl = `${baseURL}${url}/${params}`;
		axios
			.get(completeUrl, {})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err.data);
			});
	});
}
