<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	if (process.env.NODE_ENV !== 'development') console.log('Build Date:', process.env.BUILD_DATE);

	export default {
		name: 'App',
	};
</script>
