/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
	set(name, value) {
		// 如果value是对象，把value转为json字符串存储
		if (typeof value === 'object') {
			value = JSON.stringify(value);
		}
		window.localStorage.setItem(name, value);
	},

	get(name) {
		// 未经处理的请求数据
		const data = window.localStorage.getItem(name);
		try {
			// 将data转为js对象
			return JSON.parse(data);
		} catch (error) {
			return data;
		}
	},

	remove(name) {
		window.localStorage.removeItem(name);
	},

	clear() {
		window.localStorage.clear();
	},
};

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
	set(name, value) {
		// 如果value是对象，把value转为json字符串存储
		if (typeof value === 'object') {
			value = JSON.stringify(value);
		}
		window.sessionStorage.setItem(name, value);
	},

	get(name) {
		// 未经处理的请求数据
		const data = window.sessionStorage.getItem(name);
		try {
			// 将data转为js对象
			return JSON.parse(data);
		} catch (error) {
			return data;
		}
	},

	remove(name) {
		window.sessionStorage.removeItem(name);
	},

	clear() {
		window.sessionStorage.clear();
	},
};

/**
 * 判断浏览器地址栏URL是否有pathname 返回对应的storage存储key 防止多后台账户数据混乱
 * @param {String} storageKey  存储的key
 */
export const hasPathName = function (storageKey) {
	const pathname = window.location.pathname;
	if (!pathname || pathname === '/') {
		return 'main_' + storageKey;
	} else {
		return pathname.replaceAll('/', '') + '_' + storageKey;
	}
};

/**
 * 清除对应地址栏后缀的localStorage数据
 */
export const ClearWithPathNameLocal = function () {
	let pathname = window.location.pathname;
	const keys = Object.keys(localStorage);

	if (!pathname || pathname === '/') {
		pathname = 'main';
	} else {
		pathname = pathname.replaceAll('/', ''); // 如果有后缀格式为 /pathname/ 做一下截取
	}

	for (var i = 0; i < keys.length; i++) {
		if (keys[i].includes(pathname)) {
			Local.remove(keys[i]);
		}
	}
};
