<template>
	<section class="app-main">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="cachedViewsList">
				<router-view :key="key" />
			</keep-alive>
		</transition>
	</section>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		name: 'AppMain',
		computed: {
			key() {
				return this.$route.path;
			},
			...mapState({
				cachedViewsList: (state) => state.tagsView.cachedViews,
			}),
		},
	};
</script>

<style scoped>
	.app-main {
		height: calc(100vh - 85px);
		width: 100%;
		padding-bottom: 24px;
		position: relative;
		overflow: auto;
	}
	.fixed-header + .app-main {
		padding-top: 50px;
	}
</style>

<style lang="scss">
	.el-popup-parent--hidden {
		.fixed-header {
			padding-right: 15px;
		}
	}
</style>
