import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout';

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login'),
		hidden: true,
	},
	{
		path: '/dataBoard',
		name: 'dataBoard',
		component: () => import('@/views/board/dataBoard'),
		hidden: true,
	},
	{
		path: '/deviceStatus',
		name: 'deviceStatus',
		component: () => import('@/views/board/deviceStatus'),
		hidden: true,
	},
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		hidden: true,
		children: [
			{
				path: 'home',
				name: 'home',
				component: () => import('@/views/home'),
				meta: { title: '' },
				hidden: true,
			},
		],
	},
	{
		path: '/site',
		component: Layout,
		redirect: '/site/airCompressorStation',
		name: 'site',
		meta: { title: '站管理', icon: 'el-icon-s-marketing' },
		children: [
			{
				path: 'airCompressorStation',
				name: 'airCompressorStation',
				component: () => import('@/views/site/airCompressorStation'),
				meta: { title: '站管理', icon: 'el-icon-s-marketing' },
			},
		],
	},
	{
		path: '/device',
		component: Layout,
		redirect: '/device/airCompressorManage',
		name: 'device',
		meta: { title: '设备管理', icon: 'el-icon-s-marketing' },
		children: [
			{
				path: 'airCompressorManage',
				name: 'airCompressorManage',
				component: () => import('@/views/device/airCompressorManage'),
				meta: { title: '空压机管理', icon: 'el-icon-s-marketing' },
			},
		],
	},
	{
		path: '/collect',
		component: Layout,
		redirect: '/collect/kyzOnOff',
		name: 'collect',
		meta: { title: '采集数据管理', icon: 'el-icon-s-marketing' },
		children: [
			{
				path: 'kyzOnOff',
				name: 'kyzOnOff',
				component: () => import('@/views/collect/kyzOnOff'),
				meta: { title: '空压站开关机记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'kyjOnOff',
				name: 'kyjOnOff',
				component: () => import('@/views/collect/kyjOnOff'),
				meta: { title: '空压机开关机记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'fault',
				name: 'fault',
				component: () => import('@/views/collect/fault'),
				meta: { title: '故障记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'connect',
				name: 'connect',
				component: () => import('@/views/collect/connect'),
				meta: { title: '采集器连接记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'pressure',
				name: 'pressure',
				component: () => import('@/views/collect/pressure'),
				meta: { title: '压力反馈记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'airCompressor',
				name: 'airCompressor',
				component: () => import('@/views/collect/airCompressor'),
				meta: { title: '空压机采集记录查询', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'energyConsump',
				name: 'energyConsump',
				component: () => import('@/views/collect/energyConsump'),
				meta: { title: '实时能耗记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'ammeter',
				name: 'ammeter',
				component: () => import('@/views/collect/ammeter'),
				meta: { title: '电表采集记录查询', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'collvfd',
				name: 'collvfd',
				component: () => import('@/views/collect/collvfd'),
				meta: { title: '变频器采集记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'collvfdFault',
				name: 'collvfdFault',
				component: () => import('@/views/collect/collvfdFault'),
				meta: { title: '变频故障采集记录', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'dryer',
				name: 'dryer',
				component: () => import('@/views/collect/dryer'),
				meta: { title: '干燥机采集记录', icon: 'el-icon-s-marketing' },
			},
		],
	},
	{
		path: '/realtime',
		component: Layout,
		redirect: '/realtime/pressure',
		name: 'realtime',
		meta: { title: '实时数据刷新', icon: 'el-icon-s-marketing' },
		children: [
			{
				path: 'pressureRT',
				name: 'pressureRT',
				component: () => import('@/views/realtime/pressure'),
				meta: { title: '压力反馈数据', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'energyConsumpRT',
				name: 'energyConsumpRT',
				component: () => import('@/views/realtime/energyConsump'),
				meta: { title: '实时能耗数据', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'airCompressorRT',
				name: 'airCompressorRT',
				component: () => import('@/views/realtime/airCompressor'),
				meta: { title: '空压机数据', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'ammeterRT',
				name: 'ammeterRT',
				component: () => import('@/views/realtime/ammeter'),
				meta: { title: '电表数据', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'collVfdRT',
				name: 'collVfdRT',
				component: () => import('@/views/realtime/collvfd'),
				meta: { title: '变频器数据', icon: 'el-icon-s-marketing' },
			},
		],
	},
	{
		path: '/analyse',
		component: Layout,
		redirect: '/analyse/kyzRuntime',
		name: 'analyse',
		meta: { title: '数据统计分析', icon: 'el-icon-s-marketing' },
		children: [
			{
				path: 'kyzRuntime',
				name: 'kyzRuntime',
				component: () => import('@/views/analyse/kyzRuntime'),
				meta: { title: '空压站运行时长', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'kyzRuntimeChain',
				name: 'kyzRuntimeChain',
				component: () => import('@/views/analyse/kyzRuntimeChain'),
				meta: { title: '空压站运行时长(同比环比)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'kyjRuntime',
				name: 'kyjRuntime',
				component: () => import('@/views/analyse/kyjRuntime'),
				meta: { title: '空压机运行时长', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'kyjRuntimeChain',
				name: 'kyjRuntimeChain',
				component: () => import('@/views/analyse/kyjRuntimeChain'),
				meta: { title: '空压机运行时长(同比环比)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'faultStats',
				name: 'faultStats',
				component: () => import('@/views/analyse/faultStats'),
				meta: { title: '故障统计', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhStatsTime',
				name: 'nhStatsTime',
				component: () => import('@/views/analyse/nhStatsTime'),
				meta: { title: '能耗统计(按时间)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhStatsTimePeriod',
				name: 'nhStatsTimePeriod',
				component: () => import('@/views/analyse/nhStatsTimePeriod'),
				meta: { title: '能耗统计(按时段类型)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhStatsChain',
				name: 'nhStatsChain',
				component: () => import('@/views/analyse/nhStatsChain'),
				meta: { title: '能耗统计(同比环比)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhCostStatsTime',
				name: 'nhCostStatsTime',
				component: () => import('@/views/analyse/nhCostStatsTime'),
				meta: { title: '能耗费用统计(按时间)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhCostStatsPeriod',
				name: 'nhCostStatsPeriod',
				component: () => import('@/views/analyse/nhCostStatsPeriod'),
				meta: { title: '能耗费用统计(按时段类型)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'nhCostStatsChain',
				name: 'nhCostStatsChain',
				component: () => import('@/views/analyse/nhCostStatsChain'),
				meta: { title: '能耗费用统计(同比环比)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'savingEnergy',
				name: 'savingEnergy',
				component: () => import('@/views/analyse/savingEnergy'),
				meta: { title: '节能统计(按时间)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'savingEnergyPeriod',
				name: 'savingEnergyPeriod',
				component: () => import('@/views/analyse/savingEnergyPeriod'),
				meta: { title: '节能统计(按时段)', icon: 'el-icon-s-marketing' },
			},
			{
				path: 'savingEnergyChain',
				name: 'savingEnergyChain',
				component: () => import('@/views/analyse/savingEnergyChain'),
				meta: { title: '节能统计(同比环比)', icon: 'el-icon-s-marketing' },
			},
		],
	},

	{
		path: '/:pathMatch(.*)',
		redirect: '/home',
	},
];

const router = new VueRouter({
	mode: 'hash', //hash  history
	// base: process.env.BASE_URL,
	routes,
});

export default router;
