<template>
	<div class="copyright">
		<!-- <a href="http://www.vansing.net" target="_blank" rel="noopener noreferrer"> -->
		<span class="company-name">2022&copy; 重庆万显昇节能科技有限公司</span>
		<span class="tel">www.vansing.net 18602302466</span>
		<!-- </a> -->
	</div>
</template>

<script>
	export default {
		name: 'copyRight',
		data() {
			return {};
		},

		mounted() {},
	};
</script>

<style lang="scss" scoped>
	.copyright {
		width: 100%;
		height: 20px;
		text-align: center;
		white-space: nowrap;
		position: fixed;
		bottom: 5px;
		left: 0;
		pointer-events: none;
		z-index: 999999999999;

		a {
			text-decoration: none;
		}

		span {
			font-size: 14px;
			color: #1aade2;
			white-space: nowrap;
		}

		.company-name {
			margin-right: 5px;
		}

		.tel {
			margin-left: 5px;
		}
	}
</style>
