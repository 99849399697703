<template>
	<div class="sidebar-logo-container" :class="{ collapse: collapse }">
		<transition name="sidebarLogoFade">
			<router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
				<!-- <img v-if="logo" :src="logo" class="sidebar-logo" /> -->
				<!-- <h1 v-else class="sidebar-title">{{ title }}</h1> -->
				<i v-if="logo" class="el-icon-menu" style="font-size: 20px; vertical-align: middle; color: #fff"></i>
				<h1 v-if="!logo" class="sidebar-title">{{ title }}</h1>
			</router-link>
			<router-link v-else key="expand" class="sidebar-logo-link" to="/">
				<!-- <img v-if="logo" :src="logo" class="sidebar-logo" /> -->
				<h1 class="sidebar-title">{{ title }}</h1>
			</router-link>
		</transition>
	</div>
</template>

<script>
	import logo from '@/assets/logo.png';
	export default {
		name: 'SidebarLogo',
		props: {
			collapse: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				title: '空压站监测系统',
				logo: logo,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.sidebarLogoFade-enter-active {
		transition: opacity 1.5s;
	}

	.sidebarLogoFade-enter,
	.sidebarLogoFade-leave-to {
		opacity: 0;
	}

	.sidebar-logo-container {
		position: relative;
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #485b72;
		text-align: center;
		overflow: hidden;

		& .sidebar-logo-link {
			height: 100%;
			width: 100%;

			& .sidebar-logo {
				width: 32px;
				height: 32px;
				vertical-align: middle;
				margin-right: 12px;
			}

			& .sidebar-title {
				display: inline-block;
				margin: 0;
				color: #fff;
				font-weight: 600;
				line-height: 50px;
				font-size: 20px;
				font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
				vertical-align: middle;
			}
		}

		&.collapse {
			.sidebar-logo {
				margin-right: 0px;
			}
		}
	}
</style>
