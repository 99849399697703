import { Local, hasPathName } from '@/utils/storage';
export default {
	namespaced: true,

	state: {
		station: [], //空压站 /  //空压机列表
		startupTypeList: [{ label: '全部类型', value: 'all' }], //开机类型
		shutdownTypeList: [{ label: '全部类型', value: 'all' }], //关机类型
		onoffPersonList: [{ label: '全部用户', value: 'all' }], //开 / 关机人列表
		connectTypeList: [{ label: '全部类型', value: 'all' }], //连接类型
		dryingInfoList: [{ label: '全部设备', value: 'all' }], //干燥机设备列表

		// 计算周期
		cycleList: [
			// { label: '每小时', value: 'hour' },
			{ label: '每天', value: 'day' },
			{ label: '每周', value: 'week' },
			{ label: '每月', value: 'month' },
		],

		// 高低峰时段
		bucketType: [
			{ label: '全部时段', value: 'all' },
			{ label: '低估', value: 'low' },
			{ label: '平段', value: 'flat' },
			{ label: '高峰', value: 'high' },
			{ label: '尖峰', value: 'top' },
		],

		//更新频率下拉框选项
		setupOption: [
			{ label: '秒', value: 'ss' },
			{ label: '分钟', value: 'mm' },
			{ label: '小时', value: 'hh' },
		],

		//更新数据时间段 下拉框选项
		updateTime: [
			{ label: '近十分钟', value: 'ten' },
			{ label: '近半小时', value: 'hah' },
			{ label: '近一小时', value: 'hour' },
			{ label: '最近一天', value: 'day' },
		],

		// 数据平均值下拉框数据
		dataAverageList: [
			{ label: '全部', value: 'all' },
			{ label: '1秒', value: '1' },
			{ label: '3秒', value: '3' },
			{ label: '5秒', value: '5' },
			{ label: '10秒', value: '10' },
			{ label: '20秒', value: '20' },
			{ label: '30秒', value: '30' },
			{ label: '1分钟', value: '60' },
			{ label: '5分钟', value: '300' },
			{ label: '10分钟', value: '600' },
			{ label: '30分钟', value: '1800' },
			{ label: '1小时', value: '3600' },
			{ label: '24小时', value: '86400' },
		],

		// 电费 时段
		costPeriodList: [],
	},

	mutations: {
		/**
		 * 空压站列表数据
		 */
		handleStationData: (state, res) => {
			let flag = true;
			for (let i = 0; i < state.station.length; i++) {
				if (state.station[i].value === res.value && state.station[i].label === res.label) flag = false;
			}
			if (flag) state.station.push(res);

			Local.set(hasPathName('kyzList'), state.station);
		},

		/**
		 * 开关机类型列表数据
		 */
		handleONoffTypeData: (state, res) => {
			if (state.startupTypeList.length < res.length) {
				for (const item of res) {
					state.startupTypeList.push({ label: item.v, value: item.k });
					if (item.v.includes('开')) {
						const label = item.v.replace('开', '关');
						state.shutdownTypeList.push({ label: label, value: item.k });
					}
				}
			}
		},

		/**
		 * 开关机人列表数据
		 */
		handleONoffPersonData: (state, res) => {
			if (state.onoffPersonList.length < res.length) {
				for (const item of res) {
					state.onoffPersonList.push({ label: item.username, value: item.id });
				}
			}
		},

		/**
		 * 连接类型列表数据
		 */
		handleCollectorTypeData: (state, res) => {
			if (state.connectTypeList.length < res.length) {
				for (const item of res) {
					state.connectTypeList.push({ label: item.v, value: item.k });
				}
			}
		},

		/**
		 * 空气干燥机设备名称列表数据
		 */
		handleDryingInfoData: (state, res) => {
			if (state.dryingInfoList.length < res.length) {
				for (const item of res) {
					state.dryingInfoList.push({ label: item.factory_number, value: item.id });
				}
			}
		},

		/**
		 * 电费 时段
		 */
		handleElectricity: (state, res) => {
			for (const item of res) {
				if (item.range_end === 0) item.range_end = 24;
			}
			state.costPeriodList = res;
		},
	},

	actions: {
		async getfiltersData({ state, commit }) {
			/**
			 * 获取空压站 / 空压机列表
			 */
			const stationRes = await this._vm.$http.station().catch((err) => {}); //请求空压站
			if (stationRes && stationRes.code === 200) {
				for (const stationItem of stationRes.data) {
					let series = {
						label: stationItem.name_str,
						value: stationItem.id,
						children: [],
					};
					const compressorRes = await this._vm.$http.compressor(stationItem.id).catch((err) => {}); //请求空压机
					if (compressorRes && compressorRes.code === 200) {
						for (const item of compressorRes.data) {
							series.children.push({ label: item.device_code, value: item.id });
						}
					}
					commit('handleStationData', series);
				}
			}

			/**
			 * 获取开关机类型列表
			 */
			const onoffTypeRes = await this._vm.$http.onoffType().catch((err) => {});
			if (onoffTypeRes && onoffTypeRes.code === 200) {
				commit('handleONoffTypeData', onoffTypeRes.data);
			}

			/**
			 * 获取开关机人列表
			 */
			const onoffPersonRes = await this._vm.$http.onoffPerson().catch((err) => {});
			if (onoffPersonRes && onoffPersonRes.code === 200) {
				commit('handleONoffPersonData', onoffPersonRes.data);
			}

			/**
			 * 获取连接类型列表
			 */
			const collectorTypeRes = await this._vm.$http.collectorType().catch((err) => {});
			if (collectorTypeRes && collectorTypeRes.code === 200) {
				commit('handleCollectorTypeData', collectorTypeRes.data);
			}

			/**
			 * 获取空气干燥机设备名称列表
			 */
			const dryingInfoRes = await this._vm.$http.dryingInfo().catch((err) => {});
			if (dryingInfoRes && dryingInfoRes.code === 200) {
				commit('handleDryingInfoData', dryingInfoRes.data);
			}

			/**
			 * 查电费 (时段差异)
			 */
			const electricityRes = await this._vm.$http.energyConsumption().catch((err) => {});
			if (electricityRes && electricityRes.code === 200) {
				commit('handleElectricity', electricityRes.data);
			}
		},
	},

	getters: {
		// 空压站列表
		kyzList(state) {
			let stationArr = [];
			for (const item of state.station) {
				stationArr.push({ label: item.label, value: item.value });
			}
			return stationArr;
		},

		// 空压机列表
		kyjList(state) {
			let compArr = [];
			for (const item of state.station) {
				compArr = [...compArr, ...item.children];
			}
			return compArr;
		},
	},
};
