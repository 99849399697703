import Vue from 'vue';
import { Local, hasPathName } from '@/utils/storage';

// 是否有权限
const hasPermission = (userPermission) => {
	let userPermissionList = Array.isArray(userPermission) ? userPermission : [userPermission];
	// 当前用户的权限列表
	let permissionList = Local.get(hasPathName('info')).user_type.map((item) => item.group_id);
	return userPermissionList.some((e) => permissionList.includes(e));
};

// 指令
Vue.directive('per', {
	bind: (el, binding, vnode) => {
		if (!hasPermission(binding.value)) {
			el.style.display = 'none';
			setTimeout(() => {
				el.parentNode.removeChild(el);
			}, 0);
		}
	},
});

// 全局判断方法
Vue.prototype.$Has = hasPermission;
