import { mapState, mapGetters } from 'vuex';

export default {
	data() {
		return {
			isShowPopover: false, //选择数据平均值提示窗的判断值
			dataAverageValue: '60', //数据平均值选项 默认值
			selectDeviceLabel: '', //记录当前选择的空压站 或 空压机label
		};
	},

	watch: {},

	computed: {
		...mapState('filters', [
			'station', // 空压机->空压机 树形列表
			'startupTypeList', //开机类型
			'shutdownTypeList', //关机类型
			'onoffPersonList', //开 / 关机人列表
			'connectTypeList', //连接类型
			'dryingInfoList', //干燥机设备列表
			'cycleList', // 计算周期
			'bucketType', // 高低峰时段
			'setupOption', //更新频率下拉框选项
			'updateTime', //更新数据时间段 下拉框选项
			'dataAverageList', // 数据平均值下拉框数据
			'costPeriodList', // 电费 时段
		]),

		...mapGetters('filters', [
			'kyzList', // 空压站列表
			'kyjList', // 空压机列表
		]),

		// 计算表格高度
		calcTableHeight() {
			return this.pageSize * 72;
		},
	},

	beforeMount() {},

	mounted() {
		window.addEventListener('resize', (e) => {
			if (!(e.target.outerWidth / e.target.innerWidth > 1.5)) {
				this.myChart && this.myChart.resize();
			}
		});
	},

	methods: {
		/**
		 * 选择数据平均值(数据间隔时间)
		 */
		dataAverageChange() {
			this.influxQuery();
		},

		/**
		 * 选择数据源
		 */
		dataSourcesChange() {
			this.influxQuery();
		},

		/**
		 * 选择数据平均值的提示 显示/隐藏 回调
		 */
		dataAverageOnfocus(e) {
			this.isShowPopover = e;

			let timer = setTimeout(() => {
				this.isShowPopover = false;
				clearTimeout(timer);
			}, 8000);
		},
	},

	beforeDestroy() {
		this.myChart && this.myChart.dispose();
		this.myChart = null;
	},
};
