export default {
	namespaced: true,

	state: {
		opened: true,
		sidebarLogo: 'sidebarLogo',
	},

	mutations: {
		toggleSidebar: (state) => {
			state.opened = !state.opened;
		},
	},

	actions: {},

	getters: {},
};
