<template>
	<div class="navbar">
		<sidebarSwitch :is-active="opened" class="n-container" @toggleClick="toggleSideBar" />

		<breadcrumb class="breadcrumb-container" />

		<div class="right-menu">
			<el-dropdown class="avatar-container" trigger="click">
				<div class="avatar-wrapper">
					<i style="font-size: 24px; margin-right: 6px" class="el-icon-user-solid"></i>
					<p>{{ userName }}</p>
					<i class="el-icon-caret-bottom" />
				</div>
				<el-dropdown-menu class="user-dropdown">
					<el-dropdown-item @click.native="logout">
						<span>退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import SidebarSwitch from '@/components/SidebarSwitch';
	import Breadcrumb from '@/components/Breadcrumb';

	export default {
		name: 'navbar',

		components: {
			SidebarSwitch,
			Breadcrumb,
		},

		computed: {
			...mapState({
				opened: (state) => state.sidebar.opened,
				userName: (state) => state.user.userName,
			}),
		},

		methods: {
			toggleSideBar() {
				this.$store.commit('sidebar/toggleSidebar');
			},

			logout() {
				this.$store.commit('reset');
				this.$ClearWithPathNameLocal();
				this.$router.push(`/login?redirect=${this.$route.fullPath}`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		width: 100%;
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0 1px 4px #00152963;
		z-index: 999999;

		.n-container {
			line-height: 50px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background 0.3s;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				background: rgba(0, 0, 0, 0.095);
			}
		}

		.right-menu {
			float: right;
			height: 100%;

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					cursor: pointer;
					position: relative;
					display: flex;
					align-items: center;

					.user-avatar {
						width: 36px;
						height: 36px;
						border-radius: 8px;
						margin-right: 5px;
					}

					.el-icon-caret-bottom {
						position: absolute;
						right: -20px;
						top: 15px;
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
