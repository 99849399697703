<template>
	<div :class="classObj" class="app-wrapper">
		<sidebar class="sidebar-container" />
		<div class="main-container">
			<div>
				<navbar />
				<TagsView />
			</div>
			<app-main />
		</div>
		<CopyRight />
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import Navbar from './components/Navbar.vue';
	import TagsView from './components/tags/index.vue';
	import Sidebar from './components/Sidebar/index.vue';
	import AppMain from './components/AppMain.vue';
	import CopyRight from '@/components/copyright/index.vue';

	export default {
		components: {
			Navbar,
			TagsView,
			Sidebar,
			AppMain,
			CopyRight,
		},

		computed: {
			...mapState({
				opened: (state) => state.sidebar.opened,
			}),

			fixedHeader() {
				return this.$store.state.settings.fixedHeader;
			},
			classObj() {
				return {
					hideSidebar: !this.opened,
					openSidebar: this.opened,
				};
			},
		},

		methods: {},
	};
</script>

<style lang="scss" scoped>
	@import '@/styles/mixin.scss';
	@import '@/styles/variables.scss';

	.app-wrapper {
		@include clearfix;
		position: relative;
		height: 100%;
		width: 100%;
		&.mobile.openSidebar {
			position: fixed;
			top: 0;
		}
	}
	.drawer-bg {
		background: #000;
		opacity: 0.3;
		width: 100%;
		top: 0;
		height: 100%;
		position: absolute;
		z-index: 999;
	}

	.fixed-header {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9;
		width: calc(100% - #{$sideBarWidth});
		transition: width 0.28s;
	}

	.hideSidebar .fixed-header {
		width: calc(100% - 54px);
	}

	.mobile .fixed-header {
		width: 100%;
	}
</style>
